<template>
  <div 
    class="h-16 leading-16 overflow-hidden border-b text-center static line hover:bg-gray-100 singleLineWrap" 
    @mouseover="idState.showOptions = true" 
    @mouseleave="idState.showOptions = false"
  >
    <div v-if="isShowNameAvatar" class="absolute left-4 top-4 bottom-4 leading-7">
      <div
        class="absolute w-8 h-8 bg-cover rounded-full -mt-0.5 shadow-md" 
        :style="'background-color: black; background-image: url(' + thumb + ');'"
      ></div>
      <span
        class="font-sans rounded bg-gray-200 ml-7 px-2 py-1 cursor-pointer select-none"
        @click="idState.showChannelFullName = !idState.showChannelFullName"
      > 
        <template v-if="idState.showChannelFullName">
          {{ channel_name }}
        </template>
        <template v-else>
          {{ channel_name  | truncate(20) }}
        </template>
        
      </span>
      <!-- <span class="absolute offline-img h-6 w-6 inline-block mx-2"></span> -->
    </div>


    <div 
      v-else
      class="absolute left-4 timestamp-text-wrap px-8"
      :class="{
        'first': lineObj.indexCounter == 1
      }"
    >
      <!-- <span class="border rounded-sm border-grey-lighter font-mono font-medium text-xs text-black base-bg-darker-200 px-1">
        {{ $moment.unix(date).format('DD-MM-YYYY') }}
      </span> -->
    </div>

    <div v-if="narrativeTracing" class="absolute left-4 top-4 bottom-4 leading-7">
      <div
        class="absolute w-8 h-8 rounded-full -mt-0.5 shadow-md flex justify-center" 
        @click="handleTracing(idState.narrativeTracingActive, lineObj)"
        :class="{'narrative-tracing': narrativeTracing, 'narrative-tracing--active': idState.narrativeTracingActive }"
      >
        <img :src="require('../../assets/icons/checkmark-white.svg')"  class="w-3 select-none" alt="" v-if="idState.narrativeTracingActive" />
    </div>
      <!-- <span class="absolute offline-img h-6 w-6 inline-block mx-2"></span> -->
    </div>

    <span 
      class="w-full font-mono overflow-x-hidden text-base select-none cursor-eye"
    >

      <span v-for="(item, index) in reformatLine(linetext)" :key="index">
        <span class="subline font-sans" style="font-weight: 100;" v-html="item.text" @click="openVideo(id, item.time)">{{ linetext }}</span>
      </span>
    </span>

    <span 
      class="show-options absolute bottom-4 right-4 top-4 px-2" 
      v-if="idState.showOptions">

      <div class="bg-black rounded z-40 video-thumb" :style="`background-image: url('https://img.youtube.com/vi/${id}/1.jpg')`">
        <span class="absolute text-white right-3 top-4">{{ $moment.unix(date).format('YYYY-MM-DD') }}</span>
      </div>

      <!-- <span 
        class="details-img h-6 w-6 px-1 bg-no-repeat inline-block cursor-pointer"
        @click="showVideoDetails({ activetab: 0 })">
      </span>
      <span 
        class="share-img h-6 w-6 px-1 bg-no-repeat inline-block cursor-pointer"
        @click="showVideoDetails({ activetab: 3 })">
      </span>
      <span @click="showVideoDetails({ activetab: 0})" class="favorite-img h-6 w-6 px-1 bg-no-repeat inline-block cursor-pointer"></span> -->
    </span>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatSeconds } from '@/helpers/formats';
import { IdState } from 'vue-virtual-scroller'
// import dayjs from 'dayjs';

export default {
  name: 'SingleLine',
  props: [
    'id', 'linetext', 'channel_name', 'channel_id', 'bucket', 'date', 'online', 'lineObj', 'narrativeTracing', 'isShowNameAvatar', 'isShowNewVideo', 'thumb'
  ],
  mixins: [
    IdState({
      idProp: vm => vm.lineObj.line_id
    }),
  ],
  data() {
    return {}
  },
  idState () {
    return {
      showOptions: false,
      showChannelFullName: false,
      formatSeconds: formatSeconds,
      narrativeTracingActive: false,
    }
  },
  computed: {
    ...mapGetters({
      detailsState: 'search/detailsState'
    })
  },
  methods: {
    ...mapActions({
      activateVideo: 'search/activateVideo',
      narrativeTracingMutation: 'search/narrativeTracingMutation'
    }),
    // reformatDate(unixtimestamp) {
    //   return dayjs.unix(unixtimestamp)

    // },
    reformatLine(str) {
      let splits = str.split(/\$(.*?)\$/).filter((split) => split !== "");
      let arr = [];

      if (!splits[0].match(/[A-Za-z]+/)) {
        splits.shift();
      }

      for (let i = 0; i < splits.length; i) {
        if ((i === 0 && !splits[i].includes("||") )) {
          let ts = false;
          if (i + 1 < splits.length) { ts = splits[i+1].split("||")[0] - 5 }
          arr.push({time: ts, dur: false, text: splits[0]})
          i++;
        }

        if (i + 1 <= splits.length) {
          arr.push({
            time: splits[i].split("||")[0],
            dur: splits[i].split("||")[1],
            text: splits[i+1]
          })

          i = i + 2;
        }
      }

      return arr;
    },
    openVideo (id, timestamp) {
      const payload = {
        video_id: id,
        timestamp: timestamp,
        show: true
      }

      this.activateVideo(payload)
    },
    handleTracing (tracing, lineObj) {
      this.idState.narrativeTracingActive = !tracing;
      console.log(lineObj)

      this.addTraceToBackend({
        action: !tracing ? "create" : "delete",
        videoId: lineObj.videoId,
        channel: lineObj.channelId,
        date: lineObj.date,
        views: lineObj.views
      })
    },
    addTraceToBackend (obj) {
      console.log(obj)
      this.narrativeTracingMutation(obj);

    }
    // showVideoDetails ({ activetab }) {
    //   this.$emit('showVideoDetails', {
    //     show: !this.detailsState,
    //     video_id: this.lineObj.video_id,
    //     timestamp: this.start,
    //     activetab
    //   })
    // }
  }
}
</script>

<style>
.hit {
  background-color: rgb(255, 251, 176);
  border-radius: 4px;
  padding: 3px;
  font-family: 'Ilisarniq Demi';
}

.subline {
  background-color: rgb(249, 249, 249);
  border-radius: 4px;
  margin-left: 3px;
  margin-right: 3px;
}

.subline:hover {
  animation-name: fadeColor; 
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.cursor-eye {
  cursor: url('../../assets/icons/click.svg') 16 16, auto;
}

.details-img {
  background-image: url('../../assets/icons/details.svg');
}
.share-img {
  background-image: url('../../assets/icons/share.svg');
}

.favorite-img {
  background-image: url('../../assets/icons/favorite.svg');
}

.offline-img {
  background-image: url('../../assets/icons/offline.svg')
}

.show-options {
  opacity: 0;
  animation-delay: .25s;
  animation-name: fadeIn; 
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.video-thumb {
  width: 160px; 
  height: 90px;
  margin-top: -2rem;
  background-size: cover;
  background-position: 50% 50%;
  border: 1px solid #3333
}

.narrative-tracing {
  border: 2px solid rgb(111, 52, 227);
  animation: backgroundFocus ease-in-out 2s infinite;
  z-index: 100;
}

.narrative-tracing--active {
  background-color: rgb(111, 52, 227, .9) !important;
  animation: none !important;
}

@keyframes backgroundFocus {
  0% { 
    background-color: rgba(111, 52, 227, 0)
  }
  50% { background-color: rgba(111, 52, 227, .2); } 
  100% { background-color: rgba(111, 52, 227, 0); }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeColor {
   0% { background-color: rgb(249, 249, 249); }
  100% { background-color: rgb(242, 207, 255); } 
}

</style>